<template>
    <page
        id="settings"
        :title="$root.translate('Settings')"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        @statused="statused"
    >
        <div v-if="!!edited_record" class="columns">
            <div class="column col-3 col-sm-12 mb-2">
                <data-import
                    :record="edited_record"
                />
            </div>
            <div class="column col-9 col-sm-12 mb-2">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title h5">{{ $root.translate("User Management") }}</div>
                    </div>
                    <div class="card-body">
                        <invitation-expiries
                            id="default_invitation_delay"
                            name="default_invitation_delay"
                            v-model="edited_record.default_invitation_delay"
                            :required="true"
                            :saving="saving('default_invitation_delay')"
                            :error="has_error('default_invitation_delay')"
                            @input="save"
                        />
                    </div>
                </div>
                <div v-if="is_developer" class="card mt-4">
                    <div class="card-header">
                        <div class="card-title h5">{{ $root.translate("Developer Zone") }}</div>
                    </div>
                    <div class="card-body">
                        <bitbucket-connector
                            :record="edited_record"
                        />
                    </div>
                </div>
            </div>
        </div>
    </page>
</template>

<script type="text/javascript">
import { is_record_editor_page } from "@/nibnut/mixins"

import { InvitationExpiries } from "@/nibnut/components"
import DataImport from "./settings/DataImport"
import BitbucketConnector from "./settings/BitbucketConnector"

export default {
    mixins: [is_record_editor_page],
    components: {
        InvitationExpiries,
        DataImport,
        BitbucketConnector
    },
    methods: {
        grab_record_id () {
            this.record_id = 1
        }
    },
    data () {
        return {
            entity: "setting",
            entityName: "Setting:::Settings"
        }
    }
}
</script>
