<template>
    <div v-if="!!record" class="card">
        <div class="card-header">
            <div class="card-title h5">{{ $root.translate("Data Import") }}</div>
        </div>
        <div class="card-body">
            <div
                class="nibnut-aspect-ratio-box nibnut-ratio-golden"
            >
                <div>
                    <upload-input
                        name="import_file"
                        :url="!!held.import_file ? held.import_file.name : ''"
                        :accepts="upload_accepts('import_file')"
                        :uploading="!!uploading.import_file"
                        :uploaded="uploaded.import_file||0"
                        :error="has_error('import_file')"
                        :replaceable="true"
                        :deletable="false"
                        @upload="upload_file_list('import_file', $event)"
                    >
                        <template v-slot:display>
                            <div
                                class="empty"
                            >
                                <div>
                                    <open-icon glyph="file-archive" size="4x" />
                                    <p class="text-small empty-subtitle">{{ held.import_file.name }}</p>
                                </div>
                            </div>
                        </template>
                    </upload-input>
                </div>
            </div>
        </div>
        <div v-if="uploadable" class="card-footer text-center">
            <default-button
                :disabled="!!uploading.import_file"
                class="mx-2"
                @click.prevent="reset"
            >
                {{ $root.translate("Cancel") }}
            </default-button>
            <default-button
                color="primary"
                :disabled="!!uploading.import_file"
                :waiting="!!uploading.import_file"
                class="mx-2"
                @click.prevent="upload"
            >
                {{ $root.translate("Upload file...") }}
            </default-button>
        </div>
    </div>
</template>

<script type="text/javascript">
import { handles_uploads, handles_errors } from "@/nibnut/mixins"

import { UploadInput, DefaultButton, OpenIcon } from "@/nibnut/components"

export default {
    name: "DataImport",
    mixins: [handles_uploads, handles_errors],
    components: {
        UploadInput,
        DefaultButton,
        OpenIcon
    },
    mounted () {
        this.reset()
    },
    methods: {
        reset () {
            if(this.held.import_file) this.$delete(this.held, "import_file", null)
            this.hold = true
        },
        upload_accepts (name, filename = null) {
            if(filename) return !!filename.match(/\.(zip)$/i)
            return "application/zip,.zip"
        },
        upload_data () {
            return {
                entity: this.entity,
                id: this.record.id
            }
        },
        upload_attached (name, response) {
            if(response.meta && response.meta.success) this.$success(response.meta.success)
            this.reset()
        },
        upload_attach_error (name, error) {
            this.add_error(name, error.message)
            this.hold = true
        },
        upload () {
            this.hold = false
            this.upload_file("import_file", this.held.import_file)
        }
    },
    computed: {
        holding_mode () {
            return this.hold
        },
        uploadable () {
            return !!Object.keys(this.held).length
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            entity: "setting",
            hold: true
        }
    }
}
</script>
